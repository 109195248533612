const Hero = ({
  heading,
  image,
  alt,
  objPosition,
  quote,
  source,
  link,
  className = ``
}) => (
  <section className={`container bg-white pb5 mb4 ${className}`}>
    <div className="hero-grid mw8">
      <h1
        className="page-heading mv5"
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <Quote
        quote={quote}
        source={source}
        href={link}
        className="mv5"
        style={{ maxWidth: `60ch` }}
      />

      <Img
        fluid={image}
        alt={alt}
        objPosition={objPosition}
        className="br2 aspect-ratio aspect-ratio--4x3 sm:mb4"
      />
    </div>
  </section>
)

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'

import Img from '../components/Img'
import Quote from '../components/Quote'

export default Hero
